<template>

    <iframe type="text/html" :src="url" width="100%" height="100%" style="overflow: auto; border: 1px ridge blue">
    </iframe>

</template>

<script>
export default {
  data() {
    return {
      url: ""
    };
  },
  created() {
    console.log("Created")
    const urlParams = new URLSearchParams(window.location.search);
    const url = urlParams.get("url");

    this.url = "https://" + url;

  }
}
</script>

<style scoped>

</style>